<div id="content">
  <div class="main">
    <div id="map" style="width: 100%; height: 50%"></div>
    <div class="main-content">
      <a href="/assets/files/price-list-2025.docx">Скачать Прайс-Лист</a>

      <table>
        <thead>
          <th>№</th>
          <th>Наименование</th>
          <th>Размеры,мм.<br>Шир.длинна.выс.</th>
          <th>Цена за шт.<br>Руб.</th>
        </thead>
        <tbody>
          <tr>
            <th colspan="4">СКУЛЬПТУРЫ САДОВО-ПАРКОВЫЕ</th>
          </tr>
          <tr *ngFor="let flowerpot of products.sculptures">
            <td>{{flowerpot.type_id}}</td>
            <td>{{flowerpot.name}}</td>
            <td>{{flowerpot.size}}</td>
            <td>{{flowerpot.price}}</td>
          </tr>
          <tr>
            <th colspan="4">ВАЗОНЫ</th>
          </tr>
          <tr *ngFor="let flowerpot of flowerpots">
            <td>{{flowerpot.type_id}}</td>
            <td>{{flowerpot.name}}</td>
            <td>{{flowerpot.size}}</td>
            <td>{{flowerpot.price}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <footer>
    <span>
      МАФ Улан-Удэ, 2025г. Все права защищены.
    </span>
  </footer>
</div>
