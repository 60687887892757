import { HostListener, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from './services/data.service';
import { MatSidenavContent, MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSidenavContent) matSideNavContent !: MatSidenavContent;
  @ViewChild(MatSidenav) sidenav !: MatSidenav;
  contentElement
  title = 'МАФ Улан-Удэ'
  expandedToolbar = true
  headerTransparent = true
  products = []

  constructor(private router: Router, private dataService: DataService) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) this.onPageLoad();
    });
  }

  ngOnInit(): void {
    this.dataService.getProducts();
  }

  ngAfterViewInit() {
    this.contentElement = this.matSideNavContent.getElementRef().nativeElement
  }

  onPageLoad(): void {
    console.log(`### Page Loaded ###`);
    // scrool to element when root page opened
    if (this.router.url === '/') {
      let onLoadScrollTo = localStorage.getItem('onLoadScrollTo');
      if (onLoadScrollTo !== null) {
        setTimeout(() => this.scrollToElement(onLoadScrollTo), 100);
        localStorage.removeItem('onLoadScrollTo');
      }
    }

    this.showHideExpandedToolbar();
    this.setHeaderStyle();
  }

  // contentElement scroll event
  onScroll() {
    this.setHeaderStyle();
  }

  // window resize event
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.showHideExpandedToolbar();
  }

  setHeaderStyle(){
    if (this.router.url !== '/') {
      this.headerTransparent = false;
      return null;
    }

    const scrollTop = this.contentElement.scrollTop;
    if (this.headerTransparent && scrollTop > 300){
      this.headerTransparent = false;
    } else if (!this.headerTransparent && scrollTop <= 300) {
      this.headerTransparent = true;
    }
  }

  showHideExpandedToolbar() {
    if (window.innerWidth <= 660) {
      this.expandedToolbar = false;
    } else {
      this.expandedToolbar = true;
    }
  }

  scrollToElement(elementId, event = null) {
    event && event.preventDefault();

    if (this.router.url !== '/'){
      localStorage.setItem('onLoadScrollTo', elementId);
      this.router.navigate(['/']);
    } else {
      console.log(`scrolling to "${elementId}"`);

      const offset = this.contentElement.scrollTop;
      console.log(offset)
      const top = document.getElementById(elementId).getBoundingClientRect().top;
      console.log(top)
      let pos = top + offset - 60;
      this.contentElement.scrollTo({
        top: pos,
        behavior: "smooth"
      })
    }
  }
}
